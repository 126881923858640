import { computed } from "vue";
import { MenuItem } from "./MenuItem";
import { useStore } from "@/store"
import userService from "@/js/services/UserService";
import { CONNECTION_CATEGORIES_LINKS, DICTIONARY_LINKS, OBJECTS_LINKS } from "./config/connections-menu";

export function useConfigurationMenu() {
  const store = useStore();

  const menuStateMap = computed(() => new Map(Object.entries(store.state.menu || [])));

  const filterAvailableSystems = (systems) => {
    return systems
      .filter(system => {
        const feature = menuStateMap.value.get(system.routeName);
        return feature?.status === "active";
      })
      .map(system => MenuItem(system));
  };

  const processCategories = (categories) => {
    return categories
      .map(category => ({
        name: category.name,
        availableSystems: filterAvailableSystems(category.availableSystems)
      }))
      .filter(category => category.availableSystems.length > 0);
  };

  const outboundCategories = computed(() =>
    processCategories(CONNECTION_CATEGORIES_LINKS.outbound)
  );

  const inboundCategories = computed(() =>
    processCategories(CONNECTION_CATEGORIES_LINKS.inbound)
  );

  const recentlyOutboundLinks = computed(() =>
    store.state.recentlyUsedMenuItems.outboundConnections
  );

  const recentlyInboundLinks = computed(() =>
    store.state.recentlyUsedMenuItems.inboundConnections
  );

  const handleConnectionsClick = (direction, category, system) => {
    userService.patchRecentlyUsedMenuItems({
      [direction]: {
        [category]: {
          [system.text]: 1
        }
      }
    });
  };

  const objectsLinks = computed(() => {
    let links = filterAvailableSystems(OBJECTS_LINKS);
    if (!links.length) {
      return [];
    }

    if (store.state.permissions.projectRole >= 2) {
      links = links.filter(link =>
        !["objects-hss-objects", "objects-ldap-content"].includes(link.routeName)
      );
    }

    if (store.state.permissions.projectRole >= 3) {
      links = links.filter(link =>
        link.routeName !== "objects-subscriber-sip"
      );
    }

    return links;
  });

  const dictionaryLinks = computed(() => filterAvailableSystems(DICTIONARY_LINKS));

  const tabs = computed(() => {
    const availableTabs = [];

    if (outboundCategories.value.length) {
      availableTabs.push("Outbound Connections");
    }

    if (inboundCategories.value.length &&
        store.state.project.systemIds.length) {
      availableTabs.push("Inbound Connections");
    }

    const hasObjects = objectsLinks.value.length > 0;
    const hasDictionary = dictionaryLinks.value.length > 0;
    if (hasObjects || hasDictionary) {
      availableTabs.push("Objects");
    }

    return availableTabs;
  });

  return {
    tabs,
    inboundCategories,
    outboundCategories,
    recentlyOutboundLinks,
    recentlyInboundLinks,
    handleConnectionsClick,
    objectsLinks,
    dictionaryLinks
  };
}

