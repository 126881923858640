<script>
import {defineComponent} from "vue";
import MenuLinkList from "@/components/specific/menu/MenuLinkList.vue";
import { useAdministrationMenu } from "@/composition/menu/use-administration-menu";

export default defineComponent({
  name: "AdministrationMenu",

  components: {
    MenuLinkList
  },

  setup() {
    const { usersLinks, projectsLinks, organizationLinks } = useAdministrationMenu();

    return {
      usersLinks,
      projectsLinks,
      organizationLinks,
    };
  },
});
</script>

<template>
  <div class="administration-menu">
    <MenuLinkList
      title="User"
      :links="usersLinks"
    />

    <div class="right-side">
      <MenuLinkList
        v-if="projectsLinks.length"
        title="Project"
        :links="projectsLinks"
      />

      <v-divider
        v-if="projectsLinks.length && organizationLinks.length"
        class="divider"
      />

      <MenuLinkList
        title="Organization"
        :links="organizationLinks"
      />
    </div>
  </div>
</template>

<style scoped>
.administration-menu {
  display: flex;
  width: 350px;
}

.administration-menu > * {
  flex-basis: 50%;
}

.divider {
  margin: 15px 0 25px 0;
}
</style>
