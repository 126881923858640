<script>
import { defineComponent } from "vue";
import ClickableText from "@/components/basic/ClickableText.vue";

export default defineComponent({
  name: "MenuLinkList",

  components: {
    ClickableText
  },

  props: {
    title: {
      type: String,
      default: "",
    },
    links: Array,
  },

  emits: ["navigation-click"],
});
</script>

<template>
  <div class="menu-link-list">
    <h6
      v-if="title"
      class="menu-link-list__title"
    >
      {{ title }}
    </h6>

    <ul class="menu-link-list__list">
      <li
        class="menu-link-list__list-item"
        v-for="(link, i) in links"
        :key="i"
      >
        <router-link
          v-if="link.routeName && !link.routeName.startsWith('http')"
          :to="{ name: link.routeName }"
          class="menu-link-list__link"
          @click.native="() => $emit('navigation-click', link)"
        >
          {{ link.text }}
        </router-link>

        <a
          v-else-if="!link.click"
          class="menu-link-list__link"
          :href="link.routeName"
          target="_blank"
        >
          {{ link.text }}
        </a>

        <ClickableText
          v-else
          :text="link.text"
          class="menu-link-list__link"
          @click="link.click"
        />
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
.menu-link-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: var(--v-primary-base);

  &__title {
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.08rem;
  }

  & > &__list {
    list-style: none;
    padding-left: 0;
  }

  &__link {
    font-size: 13px;
    cursor: pointer;

    &::before {
      content: "\2022";
      position: relative;
      left: unset;
      padding-right: 3px;
      color: #9e9e9e6b;
      font-size: 28px;
      vertical-align: middle;
    }

    &:hover::before {
      color: #2b5593;
    }
  }
}
</style>
